import React, { useEffect, useState } from 'react';
import './CredentialLanding.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Action, CredentialTypes, CredentialUrls } from '../../dtos/credentials';
import CustomButton from '../CustomButton/CustomButton';
import QrCode from '../QrCode/QrCode';
import {
  getPresentationSession,
  initOpenIdIssuance,
  initOpenIdPresentation,
  oidc4vcAuthorize,
} from '../../services/vidcredentials';
import { processToken } from '../../utils/utils';
import { IS_PHONE_REGEX } from '../../constants/credentials';

interface CredentialLandingProps {
  isValidator: boolean;
  actions: Action[];
}

interface InitOpenIdIssuanceResponse {
  qrBase64: string;
  rawCredentialOffer: string;
}

interface InitOpenIdPresentationResponse {
  qrBase64: string;
  rawOpenid4vp: string;
}

function CredentialLanding({
  isValidator,
  actions,
}: CredentialLandingProps) {
  const navigate = useNavigate();
  const { type } = useParams();
  const [isLoaderVisible, setLoaderVisible] = useState(false);
  const [isButtonVisible, setButtonVisible] = useState(true);
  const [isPollingEnabled, setPollingEnabled] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [pollingDataResponse, setPollingDataResponse] = useState({});

  useEffect(() => {
    if (isPollingEnabled) {
      let intervalId: NodeJS.Timer;
      const fetchData = async () => {
        const response = await getPresentationSession();
        if (response.error) {
          clearInterval(intervalId);
          let retryLocation = '';
          if (type === 'authenticate') {
            retryLocation = CredentialUrls.AUTHENTICATE;
          }
          navigate('/validation-process/failure', { state: { retryLocation } });
        }
        if (response.status === 'verification_succeeds' || response.status === 'verification_failed') {
          setPollingDataResponse(response);
          const authorizeResponse = await oidc4vcAuthorize();
          processToken(authorizeResponse.access_token);
          setAuthenticated(true);
          clearInterval(intervalId);
        }
      };
      fetchData()
        .then();
      intervalId = setInterval(fetchData, 5000);
      return () => clearInterval(intervalId);
    }
    return () => {
    };
  }, [isPollingEnabled, type, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      if (type === 'authenticate') {
        setLoaderVisible(false);
        setButtonVisible(true);
        setPollingEnabled(false);
        setAuthenticated(false);
        setQrCode('');

        if ((pollingDataResponse as { status: string }).status === 'verification_succeeds') {
          navigate('/credential/issue-credential');
          return;
        }
        navigate('/validation-process/failure', {
          state: {
            retryLocation: CredentialUrls.AUTHENTICATE,
            validationError: (pollingDataResponse as
                { validationError: string }).validationError,
          },
        });
      }
      if (type === 'validate') {
        navigate('/validation-process/success', { state: { pollingDataResponse } });
      }
    }
  }, [pollingDataResponse, isAuthenticated, navigate, type]);

  function isMobile() {
    if (IS_PHONE_REGEX.test(navigator.userAgent)) return true;
    return navigator.maxTouchPoints
      && navigator.maxTouchPoints > 2
      && /Macintosh/.test(navigator.userAgent);
  }

  const handleClick = async (credentialTypeRequested: string | undefined) => {
    if (isValidator) {
      setButtonVisible(false);
      setLoaderVisible(true);
      let scope = '';
      let retryLocation = '';
      if (type === 'authenticate') {
        scope = 'EducationalId';
        retryLocation = CredentialUrls.AUTHENTICATE;
      }
      if (type === 'validate') {
        // scope = 'VerifiableAttestation';
        scope = 'VerifiableCredential';
        retryLocation = CredentialUrls.VALIDATE;
      }
      const response = await initOpenIdPresentation(scope);
      if (!!response && 'error' in response) {
        navigate('/validation-process/failure', { state: { retryLocation } });
        return;
      }
      const initOpenIdPresentationResponse:
      InitOpenIdPresentationResponse = response.data;
      setLoaderVisible(false);
      setPollingEnabled(true);
      if (isMobile()) {
        window.open(initOpenIdPresentationResponse.rawOpenid4vp, '_self');
        return;
      }
      setQrCode(initOpenIdPresentationResponse.qrBase64);
      return;
    }
    if (type === 'issue-credential') {
      navigate('/issuance-process', { state: { credential: credentialTypeRequested } });
      return;
    }
    if (type === 'educational-id') {
      setButtonVisible(false);
      setLoaderVisible(true);
      const dni = localStorage.getItem('ID-EducationalID');
      const mecdProgramCode = '40350000000';
      const response = await
      initOpenIdIssuance(
        CredentialTypes.EDUCATIONAL_ID,
        dni as string,
        mecdProgramCode,
      );
      if (!!response && 'error' in response) {
        navigate('/issuance-process/failure', { state: { retryLocation: CredentialUrls.EDUCATIONAL_ID } });
        return;
      }
      const initOpenIdIssuanceResponse:
      InitOpenIdIssuanceResponse = response.data;
      setLoaderVisible(false);
      if (isMobile()) {
        window.open(initOpenIdIssuanceResponse.rawCredentialOffer, '_self');
        return;
      }
      setQrCode(initOpenIdIssuanceResponse.qrBase64);
    }
  };

  return (
    <div className="landing-container">
      <h1 className="landing-container__header bold">
        Benvinguts al portal
        {' '}
        {isValidator ? 'de validació' : 'd\'emissió'}
        {' '}
        de credencials
      </h1>
      <div
        className="actions-container"
        style={{ justifyContent: actions.length > 1 ? 'flex-start' : 'center' }}
      >
        {actions.map((action) => (
          <div
            className="action"
            key={action.text}
            style={{ height: qrCode ? 'auto' : '180px' }}
          >
            {(isButtonVisible || isLoaderVisible) && (
              <h2 className="action__header">
                {action.text}
                {' '}
                <b className="bold">{action.boldText}</b>
              </h2>
            )}
            {!!qrCode && (
              <h2 className="action__header__qr">
                {action.textQr}
              </h2>
            )}
            {isButtonVisible && (
              <div
                id="action__button-wrapper"
                className="action__button-wrapper"
              >
                <CustomButton
                  handleClick={() => handleClick(action.credentialType)}
                  text={action.buttonText}
                  showArrow
                />
              </div>
            )}
            {isLoaderVisible && (
              <div className="action__loader-wrapper">
                <div className="loader" />
              </div>
            )}
            {!!qrCode && (
              <div
                id="action__qrCode-wrapper"
                className="action__qrCode-wrapper"
              >
                <QrCode
                  base64img={qrCode}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CredentialLanding;
